import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Ticket } from 'app/model/entity/ticket';
import { ReservationDetail } from 'app/model/entity/ticket-manager/reservationDetail';
import { DialogService } from 'app/service/dialog.service';
import _ from 'lodash';
import moment from 'moment';
import { DialogConfirmReservationComponent } from '../dialog-confirm-reservation/dialog-confirm-reservation.component';
import { StockDetail } from 'app/model/entity/ticket-manager/stockDetail';
import { ReservationPrice } from 'app/model/entity/ticket-manager/reservationPrice';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import { Helper } from 'app/common/helper';
import { TranslateService } from '@ngx-translate/core';
import { Common } from 'app/model/entity/common';
import { CommonService } from 'app/service/common.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'dialog-edit-reservation',
  templateUrl: './dialog-edit-reservation.component.html',
  styleUrls: ['./dialog-edit-reservation.component.scss']
})
export class DialogEditReservationComponent implements OnInit {
  languageKey: any;
  languageCode: any;
  listTicket: Ticket[];
  listStock: StockDetail[];
  stockDetail: StockDetail = new StockDetail();
  listPriceClass: any[];
  listReservationTime: any[];
  listNumber: number[];
  formatDate: string;
  reservationDetail: ReservationDetail;
  listDate: any[];
  priceClass: any;
  reservationTime: any;
  informationAccount: any;
  listReservationPrice: ReservationPrice[];
  reservationPriceDetail: ReservationPrice;
  reservationStatus: string;
  commonObject: Common;
  dialCodeLength: number;
  helper = Helper;
  listTripId: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogEditReservationComponent>,
    private dialogService: DialogService,
    private ticketManagerService: TicketManagerService,
    private translateService: TranslateService,
    private commonService: CommonService
  ) {
    this.dialCodeLength == 0;
    if (data.languageKey) {
      this.languageKey = data.languageKey;
      this.languageCode = this.languageKey == 'en' ? 'en' : 'ja';
    }
    if (data.reservationDetail) {
      this.reservationDetail = _.cloneDeep(data.reservationDetail);
      if (data.reservationDetail.reservationStatus) {
        this.reservationStatus = _.cloneDeep(data.reservationDetail.reservationStatus);
      }
    } else {
      this.reservationDetail = new ReservationDetail();
    }
    this.formatDate = 'yyyy-MM-dd';
    this.listNumber = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    //set listStock và listDate
    await this.getListDate(this.reservationDetail.ticketId);
    if (!this.listDate || !this.listDate.length) {
      return;
    }

    //Set listPrice
    if (!this.reservationDetail.reserveOn) {
      return;
    }
    this.stockDetail.reserveOn = this.reservationDetail.reserveOn.substring(0, 10);
    await this.getListStock(this.stockDetail.reserveOn);

    //Set listReservationTime
    if (!this.listPriceClass || !this.listPriceClass.length) {
      return;
    }
    if (!this.reservationDetail.reservationClassId) {
      return;
    }
    if (this.listPriceClass.every(e => JSON.stringify(e) != JSON.stringify(this.reservationDetail.reservationClassId))) {
      return;
    }
    this.stockDetail.reservationClassId = this.reservationDetail.reservationClassId;
    this.setPriceClass(this.stockDetail.reservationClassId);

    //Set listTripId
    if (!this.listReservationTime || !this.listReservationTime.length) {
      return;
    }
    if (this.hasMilliseconds(this.reservationDetail.reserveOn)) {
      this.reservationTime = this.removeMilliseconds(_.cloneDeep(this.reservationDetail.reserveOn));
    } else {
      this.reservationTime = _.cloneDeep(this.reservationDetail.reserveOn);
    }
    this.setReservationTime(this.reservationTime);

    //Set stockDetail
    if (!this.listTripId || !this.listTripId.length) {
      return;
    }
    if (!this.reservationDetail.tripId) {
      return;
    }
    this.stockDetail.tripId = this.reservationDetail.tripId;
    this.setTripId(this.stockDetail.tripId);
  }

  /**
   * getListDate
   * @param ticketId
   * @returns
   */
  async getListDate(ticketId: number): Promise<void> {
    this.listStock = [];
    this.listDate = [];
    this.stockDetail.reserveOn = null;
    this.listPriceClass = [];
    this.stockDetail.reservationClassName = null;
    this.listReservationTime = [];
    this.reservationTime = null;
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    this.reservationDetail['isValidateTotalPrice'] = false;

    //Chọn rỗng
    if (!ticketId) {
      return;
    }
    const date = new Date(Helper.getCurrentDateByTimeZone(this.commonObject)).toISOString().split('T')[0];
    //Chọn ticket
    const payload = {
      ticketId: ticketId,
      startDate: date,
      endDate: ''
    };
    return new Promise<void>(resolve => {
      this.ticketManagerService.getStocksCalendar(payload).subscribe(data => {
        if (!data || !data.length) {
          resolve();
        }
        this.listDate = [...new Set(data.map(e => e.date).sort((a: string, b: string) => a.localeCompare(b)))];
        resolve();
      });
    });
  }

  /**
   * getListStock
   * @param event
   * @returns
   */
  getListStock(event: any): Promise<void> {
    this.listStock = [];
    this.listPriceClass = [];
    this.stockDetail.reservationClassId = null;
    this.listReservationTime = [];
    this.reservationTime = null;
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    this.reservationDetail['isValidateTotalPrice'] = false;

    if (!event) {
      this.stockDetail.reserveOn = null;
      return;
    }
    this.stockDetail.reserveOn = event;
    const payload = {
      stockPartitionId: '',
      ticketId: this.reservationDetail.ticketId,
      startDate: this.stockDetail.reserveOn,
      endDate: this.stockDetail.reserveOn
    };
    return new Promise<void>(resolve => {
      this.ticketManagerService.getStocks(payload).subscribe(data => {
        if (!data || !data.length) {
          resolve();
        }
        this.listStock = data;
        //Set listPriceClass
        if (this.stockDetail.reserveOn && this.listStock && this.listStock.length) {
          const listPriceClass = new Set(
            this.listStock
              .filter(e => e.reserveOn.substring(0, 10) == this.stockDetail.reserveOn)
              .map(e => e.reservationClassId)
              .sort((a, b) => a.localeCompare(b))
          );
          this.listPriceClass = [...listPriceClass];
        }
        resolve();
      });
    });
  }

  /**
   * setPriceClass
   * @param priceClass
   * @returns
   */
  setPriceClass(priceClass: any): void {
    this.listReservationTime = [];
    this.reservationTime = null;
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    this.reservationDetail['isValidateTotalPrice'] = false;

    if (!priceClass) {
      return;
    }
    //Dựa theo reserveOn(YYYY-MM-DD) và reservationClassId
    const listReservationTime = new Set(
      this.listStock
        .filter(
          e =>
            e.reserveOn.substring(0, 10) == this.stockDetail.reserveOn && JSON.stringify(e.reservationClassId) == JSON.stringify(priceClass)
        )
        .map(e => e.reserveOn)
        .sort((a, b) => a.localeCompare(b))
    );
    this.listReservationTime = [...listReservationTime];
  }

  /**
   * setReservationTime
   * @param reservationTime
   */
  setReservationTime(reservationTime: string): void {
    this.listReservationPrice = [];
    this.listTripId = [];
    this.stockDetail.tripId = null;
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    this.reservationDetail['isValidateTotalPrice'] = false;

    if (!reservationTime) {
      return;
    }
    this.listTripId = [
      ...new Set(
        this.listStock
          .filter(
            e =>
              JSON.stringify(e.reservationClassId) == JSON.stringify(this.stockDetail.reservationClassId) &&
              e.reserveOn == this.reservationTime
          )
          .map(e => e.tripId)
          .sort((a, b) => a.localeCompare(b))
      )
    ];
  }

  /**
   * setTripId
   * @param tripId
   * @returns
   */
  setTripId(tripId: string): void {
    this.listReservationPrice = [];
    this.reservationPriceDetail = null;
    this.stockDetail.name = null;
    this.stockDetail.reservationPriceDescription = null;
    if (!tripId) {
      return;
    }
    const index = this.listStock.findIndex(
      e =>
        e.reserveOn == this.reservationTime &&
        JSON.stringify(e.reservationClassId) == JSON.stringify(this.stockDetail.reservationClassId) &&
        e.tripId == this.stockDetail.tripId
    );
    if (index == -1) {
      return;
    }

    //Gán lại stockDetail và stockDetail.reserveOn(YYYY-MM-DD)
    this.stockDetail = _.cloneDeep(this.listStock[index]);
    this.stockDetail.reserveOn = this.stockDetail.reserveOn.substring(0, 10);

    const payload = {
      stockPartitionId: this.stockDetail.stockPartitionId,
      ticketId: this.reservationDetail.ticketId,
      year: new Date(this.stockDetail.reserveOn).getFullYear(),
      month: new Date(this.stockDetail.reserveOn).getMonth() + 1
    };

    this.ticketManagerService.getReservationPrices(payload).subscribe(
      data => {
        this.listReservationPrice = data;
        if (this.listReservationPrice && this.listReservationPrice.length) {
          //Lấy bản ghi dựa theo reservationPriceId
          const indexReservationPrice = this.listReservationPrice.findIndex(
            e => e.reservationPriceId == this.stockDetail.reservationPriceId
          );
          if (indexReservationPrice != -1) {
            this.reservationPriceDetail = this.listReservationPrice[indexReservationPrice];
            //validate nếu totalPrice có thay đổi
            if (this.getTotalPrice() != this.reservationDetail.totalPrice) {
              this.reservationDetail['isValidateTotalPrice'] = true;
            } else {
              this.reservationDetail['isValidateTotalPrice'] = false;
            }
          }
        }
      },
      error => {}
    );
  }

  /**
   * validateBeforeSave
   */
  validateBeforeSave(): boolean {
    let flag = false;
    //representativeName
    if (!this.reservationDetail.representativeName) {
      this.reservationDetail['isValidateRepresentativeName'] = true;
      this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
      this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
      flag = true;
    } else {
      this.reservationDetail['isValidateRepresentativeName'] = false;
      if (this.isValidateFormatName(this.reservationDetail.representativeName)) {
        this.reservationDetail['isValidateRepresentativeNameFormat'] = true;
        this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
        flag = true;
      } else if (this.isValidateMinMax(this.reservationDetail.representativeName, 1, 100)) {
        this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
        this.reservationDetail['isValidateRepresentativeNameMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
        this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
      }
    }

    //phoneNumber
    if (!Helper.convertPhoneNumber(this.reservationDetail.phoneNumber)) {
      this.reservationDetail['isValidatePhoneNumber'] = true;
      this.reservationDetail['isValidatePhoneNumberFormat'] = false;
      this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
      flag = true;
    } else {
      this.reservationDetail['isValidatePhoneNumber'] = false;
      if (this.isValidateFormatPhoneNumber(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber))) {
        this.reservationDetail['isValidatePhoneNumberFormat'] = true;
        this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
        flag = true;
      } else if (this.isValidateMinMax(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber), 1, 20 - this.dialCodeLength - 1)) {
        this.reservationDetail['isValidatePhoneNumberFormat'] = false;
        this.reservationDetail['isValidatePhoneNumberMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidatePhoneNumberFormat'] = false;
        this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
      }
    }

    //Email
    if (!this.reservationDetail.email) {
      this.reservationDetail['isValidateEmailFormat'] = false;
      this.reservationDetail['isValidateEmailMinMax'] = false;
    } else {
      if (this.isValidateFormatEmail(this.reservationDetail.email)) {
        this.reservationDetail['isValidateEmailFormat'] = true;
        this.reservationDetail['isValidateEmailMinMax'] = false;
        flag = true;
      } else if (this.isValidateMinMax(this.reservationDetail.email, 0, 254)) {
        this.reservationDetail['isValidateEmailFormat'] = false;
        this.reservationDetail['isValidateEmailMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateEmailFormat'] = false;
        this.reservationDetail['isValidateEmailMinMax'] = false;
      }
    }

    //totalPrice
    if (this.reservationPriceDetail && this.reservationDetail) {
      if (this.getTotalPrice() != this.reservationDetail.totalPrice) {
        this.reservationDetail['isValidateTotalPrice'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateTotalPrice'] = false;
      }
    }

    //remarkAnswer
    if (!this.reservationDetail.remarkAnswer) {
      this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
    } else {
      if (this.isValidateMinMax(this.reservationDetail.remarkAnswer, 0, 200)) {
        this.reservationDetail['isValidateRemarkAnswerMinMax'] = true;
        flag = true;
      } else {
        this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
      }
    }

    return flag;
  }

  /**
   * confirm
   */
  confirm(): void {
    if (this.validateBeforeSave()) {
      return;
    }
    if (
      !this.reservationDetail.reservationId ||
      !this.stockDetail.stockId ||
      !this.stockDetail.reserveOn ||
      !this.stockDetail.reservationClassId ||
      !this.reservationTime ||
      !this.reservationStatus
    ) {
      return;
    }
    const priceLabelCustom1 = !this.helper.isEmpty(this.reservationDetail.priceCustom1)
      ? !this.reservationDetail.userId
        ? this.reservationDetail?.priceLabelCustom1
        : this.reservationDetail.custom1Name[this.languageCode]
      : undefined;
    const priceLabelCustom2 = !this.helper.isEmpty(this.reservationDetail.priceCustom2)
      ? !this.reservationDetail.userId
        ? this.reservationDetail?.priceLabelCustom2
        : this.reservationDetail.custom2Name[this.languageCode]
      : undefined;
    const priceLabelCustom3 = !this.helper.isEmpty(this.reservationDetail.priceCustom3)
      ? !this.reservationDetail.userId
        ? this.reservationDetail?.priceLabelCustom3
        : this.reservationDetail.custom3Name[this.languageCode]
      : undefined;
    const reservationDetail = {
      reservationId: this.reservationDetail.reservationId,
      stockId: this.stockDetail.stockId,
      representativeName: this.reservationDetail.representativeName,
      phoneNumber: this.reservationDetail.phoneNumber,
      email: this.reservationDetail.email,
      remarkAnswer: this.reservationDetail.remarkAnswer,
      ticketName: this.reservationDetail.ticketName,
      reserveOn: this.reservationTime,
      name: this.stockDetail.name,
      reservationClassId: this.stockDetail.reservationClassId,
      reservationPriceDescription: this.stockDetail.reservationPriceDescription,
      reservationNumberAdult: !this.helper.isEmpty(this.reservationPriceDetail.priceAdult)
        ? this.reservationDetail.reservationNumberAdult ?? 0
        : undefined,
      priceLabelCustom1: priceLabelCustom1,
      reservationNumberCustom1: !this.helper.isEmpty(this.reservationPriceDetail.priceCustom1)
        ? this.reservationDetail.reservationNumberCustom1 ?? 0
        : undefined,
      priceLabelCustom2: priceLabelCustom2,
      reservationNumberCustom2: !this.helper.isEmpty(this.reservationPriceDetail.priceCustom2)
        ? this.reservationDetail.reservationNumberCustom2 ?? 0
        : undefined,
      priceLabelCustom3: priceLabelCustom3,
      reservationNumberCustom3: !this.helper.isEmpty(this.reservationPriceDetail.priceCustom3)
        ? this.reservationDetail.reservationNumberCustom3 ?? 0
        : undefined,
      totalPrice: this.getTotalPrice(),
      reservationStatus: this.reservationStatus
    };
    this.dialogService.showDialog(
      DialogConfirmReservationComponent,
      {
        data: {
          reservationDetail: reservationDetail,
          languageKey: this.languageKey
        }
      },
      result => {
        if (result) {
          this.dialogRef.close(result);
        }
      }
    );
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: string): string {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 36) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }

  /**
   * convertDate
   * @param date
   * @returns
   */
  convertDate(date: string): Date {
    if (date) {
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(5, 7)) - 1;
      const day = parseInt(date.substring(8, 10));
      const newDate = moment({ year, month, day }).toString();
      return new Date(newDate);
    }
  }

  /**
   * displayDateTime
   * @param time
   * @returns
   */
  displayDateTime(time: string): string {
    // Tách các thành phần thời gian từ chuỗi
    const dateTime = new Date(time);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minute = dateTime.getMinutes();

    // Ghép lại các thành phần theo định dạng mong muốn
    const formattedDateTime = `${year}年${month}月${day}日     ${hour}:${minute.toString().padStart(2, '0')}`;
    return formattedDateTime;
  }

  /**
   * isValidateMinMax
   * @param input
   * @param min
   * @param max
   * @returns
   */
  isValidateMinMax(input: string, min: number, max: number): boolean {
    if (!input) {
      return false;
    }
    if (input.length < min || input.length > max) {
      return true;
    }
    return false;
  }

  /**
   * isNumberHalfSize
   * @param value
   * @returns
   */
  isValidateFormatPhoneNumber(value: string): boolean {
    const regex = /^[0-9]+$/;
    return !regex.test(value);
  }

  /**
   * isValidateFormatName
   * @param value
   * @returns
   */
  isValidateFormatName(value: string): boolean {
    const regex = /^[\u30A0-\u30FF\u0020\u3000\u0041-\u005A\u0061-\u007A]+$/;
    return !regex.test(value);
  }

  /**
   * isValidateFormatEmail
   * @param value
   * @returns
   */
  isValidateFormatEmail(value: string): boolean {
    const regex = /^[\w-+.!#$%&'*/=?^`{|}~]+@[\w-]+(\.[\w-]+)+$/;
    return !regex.test(value);
  }

  /**
   * validateInput
   * @param prop
   */
  validateInput(prop: string): void {
    switch (prop) {
      case 'representativeName':
        if (!this.reservationDetail.representativeName) {
          this.reservationDetail['isValidateRepresentativeName'] = true;
          this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
          this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
        } else {
          this.reservationDetail['isValidateRepresentativeName'] = false;
          if (this.isValidateFormatName(this.reservationDetail.representativeName)) {
            this.reservationDetail['isValidateRepresentativeNameFormat'] = true;
            this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
          } else if (this.isValidateMinMax(this.reservationDetail.representativeName, 1, 100)) {
            this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
            this.reservationDetail['isValidateRepresentativeNameMinMax'] = true;
          } else {
            this.reservationDetail['isValidateRepresentativeNameFormat'] = false;
            this.reservationDetail['isValidateRepresentativeNameMinMax'] = false;
          }
        }
        break;
      case 'phoneNumber':
        if (!Helper.convertPhoneNumber(this.reservationDetail.phoneNumber)) {
          this.reservationDetail['isValidatePhoneNumber'] = true;
          this.reservationDetail['isValidatePhoneNumberFormat'] = false;
          this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
        } else {
          this.reservationDetail['isValidatePhoneNumber'] = false;
          if (this.isValidateFormatPhoneNumber(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber))) {
            this.reservationDetail['isValidatePhoneNumberFormat'] = true;
            this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
          } else if (
            this.isValidateMinMax(Helper.convertPhoneNumber(this.reservationDetail.phoneNumber), 1, 20 - this.dialCodeLength - 1)
          ) {
            this.reservationDetail['isValidatePhoneNumberFormat'] = false;
            this.reservationDetail['isValidatePhoneNumberMinMax'] = true;
          } else {
            this.reservationDetail['isValidatePhoneNumberFormat'] = false;
            this.reservationDetail['isValidatePhoneNumberMinMax'] = false;
          }
        }
        break;
      case 'email':
        if (!this.reservationDetail.email) {
          this.reservationDetail['isValidateEmailFormat'] = false;
          this.reservationDetail['isValidateEmailMinMax'] = false;
        } else {
          if (this.isValidateFormatEmail(this.reservationDetail.email)) {
            this.reservationDetail['isValidateEmailFormat'] = true;
            this.reservationDetail['isValidateEmailMinMax'] = false;
          } else if (this.isValidateMinMax(this.reservationDetail.email, 0, 254)) {
            this.reservationDetail['isValidateEmailFormat'] = false;
            this.reservationDetail['isValidateEmailMinMax'] = true;
          } else {
            this.reservationDetail['isValidateEmailFormat'] = false;
            this.reservationDetail['isValidateEmailMinMax'] = false;
          }
        }
        break;
      case 'remarkAnswer':
        if (!this.reservationDetail.remarkAnswer) {
          this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
        } else {
          if (this.isValidateMinMax(this.reservationDetail.remarkAnswer, 0, 200)) {
            this.reservationDetail['isValidateRemarkAnswerMinMax'] = true;
          } else {
            this.reservationDetail['isValidateRemarkAnswerMinMax'] = false;
          }
        }
        break;
      default:
        break;
    }
  }

  /**
   * getTotalPrice
   */
  getTotalPrice(): number {
    if (!this.reservationPriceDetail) {
      return 0;
    }
    return Helper.getTotalPrice(
      this.reservationPriceDetail.priceAdult,
      this.reservationDetail.reservationNumberAdult,
      this.reservationPriceDetail.priceCustom1,
      this.reservationDetail.reservationNumberCustom1,
      this.reservationPriceDetail.priceCustom2,
      this.reservationDetail.reservationNumberCustom2,
      this.reservationPriceDetail.priceCustom3,
      this.reservationDetail.reservationNumberCustom3
    );
  }

  /**
   * changeDisplayTime
   * @param value
   * @returns
   */
  changeDisplayTime(value: string): string {
    if (!value) {
      return;
    }
    return value.substring(11, 16);
  }

  /**
   * changeDisplayStatus
   * @param status
   */
  changeDisplayStatus(status: string): string {
    if (!status) {
      return;
    }
    if (status == 'reserved') {
      return '収受済み';
    } else {
      return '未収受';
    }
  }

  /**
   * getStringTranslate
   * @param msg
   * @param param
   * @param param2
   * @returns
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`dialog-reservation.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`dialog-reservation.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * hasMilliseconds
   * @param dateString
   * @returns
   */
  hasMilliseconds(dateString: string): boolean {
    const regex = /\.\d{3}/;
    return regex.test(dateString);
  }

  /**
   * removeMilliseconds
   * @param dateString
   * @returns
   */
  removeMilliseconds(dateString: string): string {
    // Tách chuỗi thời gian thành các thành phần
    const [dateTimePart, timezonePart] = dateString.split('+');

    // Loại bỏ phần mili giây
    const dateTimeWithoutMs = dateTimePart.replace(/\.\d+/, '');

    // Ghép lại chuỗi thời gian mới
    return `${dateTimeWithoutMs}+${timezonePart}`;
  }

  /**
   * convertSpace
   */
  convertSpace(): void {
    if (!this.reservationDetail.representativeName) {
      return;
    }
    this.reservationDetail.representativeName = this.reservationDetail.representativeName.replace(/　/g, ' ');
  }
}
